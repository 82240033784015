<template>
  <div id="subscrition">
    <h2 class="text-titulo section-title">{{ $store.state.titulofix }}</h2>
    <v-spacer class="my-3"></v-spacer>
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="6" class="blue-grey lighten-5">
          <v-row dense>
            <v-col md="auto">
              <v-btn
                @click="fnReset"
                color="grey darken-3"
                class="my-2"
                large
                dark
              >
                <v-icon left>mdi-sync</v-icon>Reset
              </v-btn>
            </v-col>
            <v-col md="auto">
              <v-text-field
                label="Search"
                v-model="buscador"
                prepend-icon="mdi-account-search-outline"
                v-on:keyup.enter="fnLoad"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-data-table
            :headers="cabecera"
            :items="clientes"
            :page="pagina"
            :items-per-page="itemsPorPag"
            :options.sync="options"
            :pageCount="totalpages"
            :server-items-length="total"
            :loading="stLoading"
            item-key="id"
            class="elevation-1"
            disable-sort
            :footer-props="{
              showFirstLastPage: true,
              itemsPerPageOptions: [25, 50, 100, 250],
            }"
          >
            <template v-slot:item.modified="{ item }">
              {{ fnTimeZone(item.modified) }}
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" md="6">
          <v-sheet elevation="1" class="pa-3" color="blue-grey lighten-5">
            <v-row>
              <v-col cols="12" md="4">
                <v-select :items="grouplist" v-model="stGroup"></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-file-input
                  v-model="archivo"
                  show-size
                  accept="text/csv"
                  label="CSV file"
                ></v-file-input>
              </v-col>
              <v-col cols="12" md="4">
                <v-btn
                  color="primary"
                  large
                  :disabled="stGroup === null"
                  :loading="stBtnUpl"
                  @click="fnUpload"
                >
                  Upload<v-icon right>mdi-upload</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <div ref="logger" class="consola">logs</div>
            <br />
            <div v-if="csvdata.length">
              <json-csv
                :data="csvdata"
                class="v-btn v-btn--contained v-size--large theme--dark blue darken-4"
              >
                Download<v-icon dark right
                  >mdi-arrow-down-bold-hexagon-outline</v-icon
                >
              </json-csv>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { DateTime } from "luxon";
import JsonCsv from "vue-json-csv";

export default {
  name: "subscription",
  metaInfo: {
    title: "Subscriptions",
  },
  components: {
    JsonCsv,
  },
  data() {
    return {
      pagina: 1,
      itemsPorPag: 25,
      totalpages: 0,
      stLoading: false,
      cabecera: [
        { text: "ID", value: "id" },
        { text: "Email", value: "email" },
        { text: "Group", value: "grupo" },
        { text: "Date", value: "modified" },
      ],
      grouplist: [
        "newsletter-shopify",
        "shopifylist",
        "newslettersteven5",
        "newslettersteven4",
        "newslettersteven3",
        "newslettersteven2",
        "newslettersteven1",
        "newslettersteven",
        "newsletter7",
        "newsletter6",
        "newsletter5",
        "newsletter4",
        "newsletter3",
        "newsletter2",
        "newsletter1",
        "newsletter",
      ],
      clientes: [],
      options: {},
      total: 0,
      stGroup: null,
      archivo: null,
      stBtnUpl: false,
      csvdata: [],
      buscador: null,
    };
  },
  mounted() {
    this.fnLoad();
    this.$store.state.titulofix = "Newsletter Subscriptions";
  },
  watch: {
    options: {
      handler() {
        this.fnLoad();
      },
    },
  },
  methods: {
    fnLoad() {
      this.stLoading = true;
      const { page, itemsPerPage } = this.options;
      let pageNumber = page - 1;
      axios
        .post("/subscription", {
          page: pageNumber,
          limit: itemsPerPage,
          word: this.buscador,
        })
        .catch(() => {
          this.$store.state.mensaje =
            "Error: server side error. Wait a moment and try again.";
          this.$store.state.estado = "error";
          this.$store.state.snackme = true;
          this.$errorsnd();
          this.stLoading = false;
        })
        .then((response) => {
          this.clientes = response.data.list;
          this.totalpages = response.data.pages;
          this.total = response.data.total;
          this.stLoading = false;
        });
    },
    fnReset() {
      this.buscador = null;
      this.fnLoad();
    },
    fnTimeZone(val) {
      const fecha = DateTime.fromSQL(val, { zone: "America/New_York" });
      return fecha.toLocaleString(DateTime.DATETIME_MED);
    },
    fnArchivo(e) {
      let files = e.target.files || e.dataTransfer.files;
      console.info(files.length);
    },
    fnUpload() {
      if (this.archivo) {
        let paquete = new FormData();
        this.stBtnUpl = true;

        paquete.append("file", this.archivo, this.archivo.name);
        paquete.append("group", this.stGroup);
        axios
          .post("/uploadcsv", paquete)
          .then((response) => {
            this.$refs.logger.innerHTML = response.data.join("<br />");
            this.csvdata = response.data;
          })
          .catch(() => {
            this.$store.state.mensaje =
              "Error: server side error. Wait a moment and try again.";
            this.$store.state.estado = "error";
            this.$store.state.snackme = true;
            this.$errorsnd();
          });
      }
      this.stBtnUpl = false;
    },
  },
  computed: {
    stSinArch: ({ archivo }) => archivo.length === 0,
  },
};
</script>

<style type="text/css" scoped>
.consola {
  background: black;
  color: #00c853;
  font-family: monospace;
  padding: 5px;
  height: 300px;
  width: 100%;
  overflow: auto;
}
</style>
